<template>
<middleware-sidebar-outline
  :uuid="uuid"
  :middlewareID="middlewareID"
  :middlewareType="middlewareType"
  @close="$emit('close')"
  @shown="$emit('shown')"
  @saveMiddleware="buildAgents"
  v-model="variables"
>
  <div class="mb-3" v-if="postgreSqlSelectData.connection">
    <b-row class="mt-1 border rounded shadow mx-0 py-75">
      <h4 class="w-100 ml-1">Connection Info</h4>

      <b-col cols="6">
        <connection-register-input class="my-50" :connectionTypeID="2" v-model="postgreSqlSelectData.connection"/>
      </b-col>
    
      <b-col v-for="pos in renderCustomInputList" :key="pos.label" :cols="pos.cols">
        <div>
          <custom-input
            v-if="postgreSqlSelectData[pos.label] != undefined && variables"
            label="Destiny"
            :id="`custom-input-${pos.label}`"
            class="my-50"
            :possibleValues="variables"
            v-model="postgreSqlSelectData[pos.label].register_1"
          >
            <template #label>
              <span class="text-capitalize">
                {{ pos.label }} <helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter/>
              </span>
            </template>
          </custom-input>
          <div v-else class="mb-2">
            <b-skeleton class="mb-25" width="25%" height="12px"/>
            <b-skeleton class="mb-25" width="100%" height="37px"/>
            <b-skeleton class="mb-25" width="25%" height="14px"/>
          </div>
        </div>
      </b-col>
      

    </b-row>

    <b-row class="mt-1 border rounded shadow mx-0 py-75">
      <b-col cols="12">
        <custom-input-list
          v-if="variables"
          :key="getID(version)"
          :middlewareID="middlewareID"
          :variables="variables"
          v-model="postgreSqlSelectData.cols"
          setRegister="register_1"
          :defaultAgentValue="defaultColsAgent"
          @deleteItem="deleteItem"
          @itemAdded="itemAdded"
        >
          <template #title>
            <h4 for="logic-expression-where">Cols <helper-tooltip tooltipPlacement="right" size="15" innerText="eopae" innter/></h4>
          </template>
        </custom-input-list>
      </b-col>
    </b-row>

    <b-row class="mt-1 border rounded shadow mx-0 py-75">
      <b-col cols="12">
        <h4 for="logic-expression-where">Where <helper-tooltip tooltipPlacement="right" size="15" innerText="eopae" innter/></h4>

        
        <select-where
          v-if="variables"
          v-model="postgreSqlSelectData.where"
          :possibleValues="variables"
        >  
        </select-where>
      </b-col>
    </b-row>
  </div>
</middleware-sidebar-outline>      
</template>

<script>
import { 
  BPopover,
  BIcon,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BContainer,
  BSidebar,
  BSpinner,
  BTabs,
  BTab,
  BCardText,
  BCardBody,
  BCard,
  BAvatar,
  BSkeleton,
  BFormCheckbox,
  BCollapse,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VariablesPanel from "../../VariablesPanel.vue";
import { makeToast } from '@/layouts/components/Popups'
import Source from '@/custom/class/Agent/Source'
import ActionsReorderList from '@/layouts/components/Transmission/Middleware/Agent/ActionsReorderList.vue'
import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
import HelperTooltip from '@/layouts/components/HelperTooltip'
import Sources from '@/custom/class/Enum/Sources.js'
import VSelect from 'vue-select'
import LogicExpression from '../../Comparison/LogicExpression.vue'
import DefaultAgent from '@/layouts/components/Transmission/Middleware/Agent/DefaultAgent';
import CustomInputList from '@/layouts/components/Transmission/Middleware/CustomInputList.vue';
import { VueSelect } from 'vue-select';


import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-markup.js';
// import 'prismjs/components/prism-xml-doc.js';
// import 'prismjs/components/prism-json.js';
import 'prismjs/components/prism-sql.js';
import 'prismjs/themes/prism-tomorrow.css';

import CertificateInput from '@/views/pages/middleware/MiddlewareManagement/Components/CertificateInput.vue';
import MiddlewaresMixin from '@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewaresMixin.js'
import selectWhere from '@/views/pages/middleware/MiddlewareManagement/Components/Comparison/SelectWhereSQL.vue'
import ConnectionRegisterInput from '@/views/pages/middleware/MiddlewareManagement/Components/ConnectionRegisterInput.vue'
import Middlewares from "@/custom/class/Enum/Middlewares.js"
import MiddlewareSidebarOutline from "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareSidebarOutline.vue"
import * as MiddlewareFunctions from  "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareFunctions.js"


  export default {
    mixins: [MiddlewaresMixin],
    components: {
      BPopover,
      BSidebar,
      BIcon,
      BButton,
      BRow,
      BCol,
      BInputGroup,
      BInputGroupAppend,
      BFormInput,
      BContainer,
      VuePerfectScrollbar,
      VariablesPanel,
      BSpinner,
      BTabs,
      BTab,
      BCardText,
      BCard,
      BAvatar,
      BCardBody,
      ActionsReorderList,
      PrismEditor,
      CustomInput,
      BSkeleton,
      HelperTooltip,
      VSelect,
      LogicExpression,
      CustomInputList,
      BFormCheckbox,  
      BCollapse,
      VueSelect,
      CertificateInput,
      selectWhere,
      ConnectionRegisterInput,
      MiddlewareSidebarOutline
    },
    props: {
      i18nKey: {
        type: String,
        default: "postgresql_select"
      },
      middlewareID: {
        type: Number,
        required: true,
      },
      uuid: {
        type: String,
        required: true,
      },
      value: {
        type: Array,
        required: true,
      }
    },
    data() {
      return {
        uuidMap: {},
        variables: undefined,
        variant_map: undefined,
        middlewareType: new Middlewares().items.find(el=> el.id == 49),

        agentList: [],
        isSaving: false,
        version: 0,
        changingPosition: undefined,
        changerSelection: null,
        selectChangerOptions: undefined,

        renderCustomInputList: [
          // { label: 'username',  cols: "6" },
          // { label: 'password',  cols: "6" },
          // { label: 'database',  cols: "4" },
          // { label: 'host',      cols: "4" },
          { label: 'table',     cols: "6" },
          // { label: 'port',      cols: "3" },
        ],

        postgreSqlSelectData: {
          // username: '',
          // password: '',
          // database: '',
          // host:     '',
          table:    '',
          cols:     [],
          where:    '',
          // port:     '',
          // ssl:      '',
          // certificate: ''
          connection: ''
        },

        defaultValue: {
          username: '',
          password: '',
          database: '',
          host:     '',
          table:    '',
          cols:     '',
          where:    '',
          port:     '5432',
          ssl:      'disabled',
          certificate: '',
          connection: ''
        },

        fieldName: {
          username: 'USERNAME',
          password: 'PASSWORD',
          database: 'DATABASE',
          host:     'HOST',
          table:    'TABLE',
          cols:     'COLUMN',
          where:    'WHERE',
          port:     'PORT',
          ssl:      'TLS',
          certificate: 'CERTIFICATE',
          connection: 'CONNECTION'
        },


        defaultSource: {
          username: 7,
          password: 7,
          database: 7,
          host:     7,
          table:    7,
          where:    7,
          cols:     7,
          port:     7,
          ssl:      7,
          connection: 7
        },
        // sslOptions: ["disabled", "require", "verify-ca", "verify-full"],

        agentModifiers: {
        }
      }
    },
    computed: {
      agents: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      defaultColsAgent() {
        return DefaultAgent.setAgent(this, {
          id: null,
          enum_agent_action_id: 14,
          enum_agent_block_id: 1,
          register_1: {
            source: 7,
            value: ''
          },
          register_2: {
            source: null,
            value: null,
          },
          register_destiny: {
            source: 21,
            value: 'COLUMN',
          },
          execution_order: 1,
          fatal_on_fail: true,
        })
      }
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
        this.initializeAgents();
      },
      highlighter(code) {
        return highlight(code, languages.sql); // languages.<insert language> to return html with markup
      },
      configureVars() {
        this.variant_map = this.$refs[this.getID("variables-panel")].getVariantMap();
        this.variables = this.$refs[this.getID("variables-panel")].getAllVars();
      },
      isObject(item) {
        return item instanceof Object && !(item instanceof Array);
      },
      isArray(item) {
        return item instanceof Array;
      },
      buildAgents() {
        this.isSaving = true;
        
        Object.keys(this.postgreSqlSelectData).forEach((key) => {
          
          switch (true) {
            case (this.postgreSqlSelectData[key] instanceof Array): {
              if (this.postgreSqlSelectData[key].length) {
                this.postgreSqlSelectData[key].forEach((item) => {
                  this.agentList.push(DefaultAgent.defineToAPI(item, this.middlewareID));
                });
              }
            } break;
            default: {
              if (this.agentModifiers[key]) {
                this.agentModifiers[key](this.postgreSqlSelectData[key])
              }
              this.agentList.push(DefaultAgent.defineToAPI(this.postgreSqlSelectData[key], this.middlewareID));
            } break;
          }
        })
        this.saveAgents();
      },
      saveAgents() {
        this.$store
          .dispatch("saveAgents", {
            agentList: this.agentList,
            transmissionID: this.$route.params.transmissionID,
          })
          .then((response) => {
            makeToast({
              title: this.$t("agent.toast.create_agents.success.title"),
              text: this.$t("agent.toast.create_agents.success.message"),
              variant: "success",
              icon: "CheckIcon",
            });
            this.$emit("saved", response.data);

          })
          .catch((error) => {
            this.isSaving = false;
          });
      },
      itemAdded() {
        this.version++;
      },
      deleteItem(index) {
        if (this.postgreSqlSelectData.cols[index].id) {
          this.$store
            .dispatch("deleteAgent", {
              id: this.postgreSqlSelectData.cols[index].id,
              transmissionID: this.$route.params.transmissionID,
            })
            .then(() => {
              makeToast({
                title: this.$t("agent.toast.delete.success.title"),
                text: this.$t("agent.toast.delete.success.message"),
                variant: "success",
                icon: "CheckIcon",
              });
              this.postgreSqlSelectData.cols.splice(index, 1);
              this.version++;
            })
            .catch(() => {
              makeToast({
                title: this.$t("agent.toast.delete.error.title"),
                text: this.$t("agent.toast.delete.error.message"),
                variant: "danger",
                icon: "XIcon",
              });
              this.version++;
            });
          } else {
            this.comparisonData.cols.splice(index, 1);
            this.version++;
          }
      },
      initializeAgents() {
        Object.keys(this.postgreSqlSelectData).forEach((pos) => {
          this.postgreSqlSelectData[pos] = MiddlewareFunctions.getValueFrom(
            {
              default: DefaultAgent.setAgent(this, {
                id: null,
                enum_agent_action_id: this.defaultAgentActionID,
                enum_agent_block_id: this.defaultAgentBlockID,
                register_1: {
                  source: this.defaultSource[pos],
                  value: this.defaultValue[pos],
                },
                register_2: {
                  source: null,
                  value: null,
                },
                register_destiny: {
                  source: 21,
                  value: this.fieldName[pos],
                },
                execution_order: 1,
                fatal_on_fail: true,
              }),
              source: 21,
              value: this.fieldName[pos],
              from: {
                value: "register_destiny",
                source: "register_destiny",
              },
              modifier: false,
              multiple: false,
              expected: {
                source: "7",
                from: "register_1",
              },
            },
            this.agents
          );
        })

        this.postgreSqlSelectData.cols = MiddlewareFunctions.getValueFrom(
          {
            default: [],
            source: 21,
            value: 'COLUMN',
            from: {
              value: "register_destiny",
              source: "register_destiny",
            },
            modifier: false,
            multiple: true,
            expected: {
              source: "7",
              from: "register_1",
            },
          },
          this.agents
        );

      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      sidebarShown() {
this.$emit("shown")
        this.isSaving = false;

        let el = document.getElementsByTagName("html");
        el[0].classList.add("hide-scrollbar");
      },
      sidebarHidden() {
        this.$emit("close");

        let el = document.getElementsByTagName("html");
        el[0].classList.remove("hide-scrollbar");
      },
      toggleSidebar() {
        this.$root.$emit("bv::toggle::collapse", this.uuid);
      },      
    }

  }
</script>

<style lang="scss" scoped>

  .sidebar-container{
    max-height: 97vh !important;
    height: fit-content !important;
    overflow-y: auto !important;
  }
  
  .sidebar-fixed-header{
    position: sticky !important;
    top: 0 !important;
    z-index: 2;
  }
  
  .sidebar-content{
    position: relative !important;
    padding-bottom: 20px;
    margin-bottom: 70px !important;
  }
  
</style>

<style lang="scss" scoped>
.agent-text {
  display: block !important;
  width: 300px;
}

.my-editor {
  background: #161d31;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;

  pre, textarea {
    background: none !important;
    border: none !important;
    outline: none !important;

    &:focus, &:active, &:focus-visible {
      border: none !important;
      outline: none !important;
    }
  }
}

.height-500 {
  height: 500px;
}

</style>
<style lang="scss">
.bg-default,
.b-sidebar-header {
  background-color: #151925 !important;
}

.agent-sidebar {
  .b-sidebar-right {
    border-left: solid 1px #0d111c !important;
  }

  .b-sidebar-body {
    overflow: hidden !important;
  }

  .control-height {
    position: relative;
    overflow: auto;

    min-height: 100%;
    padding-bottom: 50px;
  }

  .limit-height {
    height: calc(100% - 30%) !important;
  }

  .saving-adjust-text-position {
    position: relative;
    top: 2px;
  }
}

</style>